import React from 'react';
import styles from './CardLink.module.scss';
import Link from 'next/link';
import { Body, H4 } from 'src/features/shared/components/typography';

interface ICardLink {
  href: string;
  icon: React.ElementType;
  title: string;
  description: string;
}

export const CardLink = ({
  href,
  icon: Icon,
  title,
  description,
}: ICardLink) => (
  <Link className={styles.link} href={href}>
    <section className={styles.card}>
      <Icon className={styles.cardIcon} />
      <div className={styles.textContainer}>
        <H4 className={styles.cardTitle}>{title}</H4>
        <Body className={styles.description}>{description}</Body>
      </div>
    </section>
  </Link>
);
