import styles from './Error404Page.module.scss';
import {
  Col,
  Container,
  Row,
  Section,
} from '@features/shared/components/layout';
import { useIntl } from 'domains/i18n';
import Image from 'next/image';
import Icons from 'src/features/shared/components/icons';
import { Body, H1, H2 } from 'src/features/shared/components/typography';
import { ROUTES } from 'src/features/shared/utils/routes';
import { useEffect } from 'react';
import { pushGA4CustomErrorEvent } from 'src/features/shared/utils/ga4/push-ga4-event';
import { CardLink } from './card-link/CardLink';

export interface IErrorPageProps {
  buttonLabel?: string;
  onClick?: () => void;
  iconSrc?: string;
  className?: string;
}

const DEFAULT_ICON = '/static/images/sportcity/error-page/not-found.svg';

export const Error404Page = () => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    pushGA4CustomErrorEvent({
      error_category: 'user error',
      error_type: '404',
      error_message: formatMessage('error-400-page.title'),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Section className={styles.section}>
      <Container className={styles.container}>
        <Row className={styles.imageRow}>
          <Col columns="xs12">
            <Image
              width={100}
              height={100}
              src={DEFAULT_ICON}
              alt="error-icon"
              className={styles.image}
            />
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col columns="xs12 md:4" offset="md:4">
            <H1 className={styles.title}>
              {formatMessage('error-400-page.title')}
            </H1>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col columns="xs12 md:4" offset="md:4">
            <Body className={styles.textCenter}>
              {formatMessage('error-400-page.title.description')}
            </Body>
          </Col>
        </Row>
        <Row className={styles.row}>
          <Col columns="xs12 md:4" offset="md:4">
            <H2>{formatMessage('error-400-page.what-now')}</H2>
            <Body className={styles.textLH}>
              {formatMessage('error-400-page.what-now.description')}
            </Body>
          </Col>
        </Row>

        <Row className={styles.row}>
          <Col columns="xs12 md:4" offset="md:4">
            <div className={styles.cardsContainer}>
              <CardLink
                href={ROUTES.HOME}
                icon={Icons.UniqueBuildingLarge}
                title={formatMessage('error-400-page.cards.section.home')}
                description={formatMessage(
                  'error-400-page.cards.section.home.description',
                )}
              />

              <CardLink
                href={ROUTES.MEMBERSHIP}
                icon={Icons.CardLarge}
                title={formatMessage(
                  'error-400-page.cards.section.memberschip',
                )}
                description={formatMessage(
                  'error-400-page.cards.section.memberschip.description',
                )}
              />

              <CardLink
                href={ROUTES.CLUBS}
                icon={Icons.LocationLarge}
                title={formatMessage(
                  'error-400-page.cards.section.find-your-club',
                )}
                description={formatMessage(
                  'error-400-page.cards.section.find-your-club.description',
                )}
              />

              <CardLink
                href={ROUTES.FAQ}
                icon={Icons.DumbbellLarge}
                title={formatMessage('error-400-page.cards.section.faq')}
                description={formatMessage(
                  'error-400-page.cards.section.faq.description',
                )}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
};
